import * as React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
export default function LineStat() {
  const data = [
    { name: "Jan", fraud: 4000, notfraud: 2400, total: 6400 },
    { name: "Feb", fraud: 3000, notfraud: 1398, total: 4398 },
    { name: "Mar", fraud: 2000, notfraud: 6800, total: 8800 },
    { name: "Apr", fraud: 2780, notfraud: 3908, total: 6288 },
    { name: "May", fraud: 1890, notfraud: 4800, total: 6280 },
    { name: "Jun", fraud: 2390, notfraud: 3800, total: 5100 },
    { name: "Jul", fraud: 3490, notfraud: 4300, total: 7700 },
    { name: "Aug", fraud: 4000, notfraud: 2400, total: 6400 },
    { name: "Sep", fraud: 3000, notfraud: 1398, total: 4398 },
    { name: "Oct", fraud: 2000, notfraud: 6800, total: 7800 },
    { name: "Nov", fraud: 2780, notfraud: 3908, total: 5900 },
    { name: "Dec", fraud: 1890, notfraud: 4800, total: 6300 },
  ];
  return (
    <>
      <ResponsiveContainer
        width="100%"
        height={290}
        className="shadow-inner bg-white rounded-2xl mt-[10px] mx-auto mb-[50px] py-1"
      >
        <LineChart
          data={data}
          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="1" />
          <Tooltip />
          <Legend />
          <Line
            isAnimationActive={true}
            animationDuration={1500}
            type="monotone"
            dataKey="fraud"
            stroke="rgba(255, 0, 0, 0.3)"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            dot={{ r: 2, fill: "#FFFFFF", stroke: "#FF0000", strokeWidth: 3 }}
            style={{
              filter: "drop-shadow(0px 0px 5px rgba(255, 0, 0, 0.8))",
            }}
          />
          <Line
            type="monotone"
            dataKey="notfraud"
            stroke="rgba(0, 255, 0, 0.3)"
            strokeWidth={2}
            dot={{ r: 2, fill: "#FFFFFF", stroke: "#00FF00", strokeWidth: 3 }}
            style={{
              filter: "drop-shadow(0px 0px 5px rgba(0, 255, 0, 0.8))",
            }}
          />
          <Line
            type="monotone"
            dataKey="total"
            stroke="rgba(138, 43, 226, 0.3)"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            dot={{ r: 2, fill: "#FFFFFF", stroke: "#8A2BE2", strokeWidth: 3 }}
            style={{
              filter: "drop-shadow(0px 0px 5px rgba(138, 43, 226, 0.8))",
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
