import "@vonage/vwc-icon";
import * as React from "react";
import EditAlert from "./EditAlert.jsx";
import { useState, useEffect, useContext } from "react";
import { getAllAlerts, getArchiveAlerts } from "../../../service/Endpoints.js";
import { useOktaAuth } from "@okta/okta-react";
import useHttp from "../../../hooks/useHttp.js";
import { Divider, Tooltip } from "@mui/material";
import { AlertDetailsRespose } from "../../../dto/ResponeDTO.ts";
import Pagination from "@mui/material/Pagination";
import { PagableFilter } from "../../../dto/ApplicationDTO.ts";
import { PageLoaderContext } from "../../utils/PageLoader.jsx";
import FilterComponent from "./Filter.jsx";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EditNoteIcon from '@mui/icons-material/EditNote';


const styleAlertCard = {
  card: "bg-white m-auto mb-5 h-[9vh] rounded-lg bg-opacity-90 flex flex-row drop-shadow-md overflow-x-hidden alert-card-entry-animation p-1 w-[33vw] px-2",
  nameToolTip:
    "absolute bottom-14 left-20 scale-0 rounded-2xl bg-fuchsia-100 p-2 text-sm text-neutral-950 group-hover:scale-100",
  nameContainer: "text-base mx-5 text-gray-500",
  typeToolTip:
    "absolute bottom-14 left-200 scale-0 rounded-2xl bg-fuchsia-100 font-normal p-2 text-sm text-neutral-950 group-hover:scale-100",
  calenderToolTip:
    "absolute bottom-[10px] right-[100px] scale-0 rounded-2xl bg-fuchsia-100 font-normal p-2 text-sm text-neutral-950 group-hover:scale-100",
  new: "text-center",
  newContainer: "font-bold text-xl mx-5 mt-6 text-red-600",
  assigned:
    "font-semibold text-xs mt-2 text-blue-600 flex flex-col text-wrap w-[8vw]",
  calander:
    "flex flex-row text-[0.6vw] text-center text-gray-400 rounded-lg font-medium",

  editButton:
    "shadow-md rounded-[5px] h-8 w-8 rounded-xs text-gray-500 text-sm transition ease-in-out delay-20  hover:scale-110 hover:duration-300 active:shadow-inner active:shadow-shadow-slate-600 border-[0.5px] mt-[10px]",
};

const stylePagination = {
  container:
    "flex flex-row justify-center mt-10 absolute m-auto w-full bottom-[30px]",
  btn: "h-10 shadow-xl shadow-gray-200 rounded-lg text-fuchsia-700 text-2xl transition ease-in-out delay-20  hover:scale-110 hover:duration-300",
};

const alertDataCard = (
  { alertId, alertType, assignedTo, createdOn,accountId,verdictOn,verdict, country },
  onEditAlertHandler
) => {
  return (
    <>
      <div key={alertId} className={styleAlertCard.card}>
        {verdict && verdict != "NOT DETERMINED" && (
          <div
            className={`absolute inset-0 rounded-lg border-[2px] ${
              verdict === "FRAUD" ? "border-red-600" : "border-green-600"
            } blur-[2px] opacity-70 animate-pulse duration-500`}
          ></div>
        )}
          <div className="text-base text-purple-600 flex flex-row gap-1 space-y-[1px] w-[20vw]">
            <div className="text-gray-500 text-[0.65vw]">
              <p className="-mb-1">Alert Id: </p>
              <p className="-mb-1">Type: </p>
              <p className="-mb-1">AccountId: </p>
              <p className="-mb-1">Country: </p>
            </div>
            <div className="text-purple-600 font-medium tracking-tighter text-[0.65vw]">
              <p
                className={
                  "font-semibold tracking-tighter text-[0.7] text-gray-500 -mb-1"
                }
              >
                {alertId}
              </p>
              <p className="-mb-1">{alertType}</p>
              <p className={`-mb-1 ${accountId?"":"text-gray-300"}`}>{accountId?accountId:"N/A"}</p>
              <p className={`-mb-1 ${country?"":"text-gray-300"}`}>{country?country:"N/A"}</p>
            </div>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginX: 1, marginY: 1 }}
        />
        <div className={styleAlertCard.assigned}>
        <div className="text-blue-700 text-[0.5vw] bg-indigo-100 pl-2 pr-2 rounded-2xl w-fit tracking-tighter h-fit mb-2">
            <span className="">Created On: </span>
            <span className="font-bold">{createdOn}</span>
          </div>
          {assignedTo ? (
            <div className="flex flex-row">
              <div className="text-green-300">
                <AssignmentIndIcon />
              </div>
              <div className="text-wrap w-[200px] text-[0.7vw] ">
                {assignedTo}
                {verdict && verdict != "NOT DETERMINED" && (
                  <>
                    <div className={styleAlertCard.calander + " underline mt-2"}>
                      <div>
                        <p className="text-blue-500 underline">Verdict:</p>
                      </div>
                      <div
                        className={`font-bold ${
                          verdict === "FRAUD"
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {verdict}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-row">
              <div className="text-gray-300">
                <AssignmentIndIcon />
              </div>
              <div className="text-wrap text-red-500 w-[200px]">N/A</div>
            </div>
          )}
        </div>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginX: 1, marginY: 1 }}
        />
        <div className="text-sm w-[40px] z-10">
        <Tooltip title="Take Action">
          <button
            onClick={() => onEditAlertHandler(alertId)}
            className={styleAlertCard.editButton}
          >
            <EditNoteIcon/>
          </button>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default function DataViewer({ notFilter = [], fixedFilter = [], type="none" }) {
  //State---------------------------------------------------------------------------------
  const [alerts, setAlerts] = useState(new AlertDetailsRespose());
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, serSortOrder] = useState("asc");
  const [totalPage, setTotalPage] = useState(0);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState();
  const [paginationFilter, setPaginationFilter] = useState(new PagableFilter());
  //---------------------------------------------------------------------------------State

  //hooks---------------------------------------------------------------------------------
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, error, sendRequest: callApi } = useHttp();
  //---------------------------------------------------------------------------------hooks

  //Context---------------------------------------------------------------------------------
  const pageLoaderContext = useContext(PageLoaderContext);
  //---------------------------------------------------------------------------------Context

  //SideEffects---------------------------------------------------------------------------------
  useEffect(() => {
    pageLoaderContext.showLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setTotalPage(Math.ceil(alerts.totalRecords / pageLimit));
  }, [alerts]);

  useEffect(() => {
    let pageable = new PagableFilter();
    pageable.limit = pageLimit;
    pageable.offset = 0;
    pageable.sortBy = sortField;
    pageable.sortDir = sortOrder;
    setPaginationFilter(pageable);
  }, []);

  useEffect(() => {
    if (paginationFilter.limit > -1 && paginationFilter.offset > -1)
      getAlertsFromAPI(paginationFilter);
  }, [paginationFilter]);
  //---------------------------------------------------------------------------------SideEffects

  //Constants---------------------------------------------------------------------------------
  const sortField = "recievedOn";
  const sortTypes = ["Oldest", "Newest"];
  const pageLimit = 20;
  //---------------------------------------------------------------------------------Constants

  //API Calls---------------------------------------------------------------------------------
  const getAlertsFromAPI = React.useCallback((pagableFilter) => {
    let newPagination = { ...pagableFilter };
    newPagination.filters = pagableFilter.filters.concat(fixedFilter);
    let token = authState.accessToken.accessToken;
    console.log(type)
    if (type == "history"){
      callApi(
        { url: getArchiveAlerts(pagableFilter) },
        (response) => {
          const responseBody = new AlertDetailsRespose();
          responseBody.applyData(response);
          setAlerts(responseBody);
        },
        token
      );
    }
    else{
      callApi(
        { url: getAllAlerts(newPagination) },
        (responseData) => {
          const response = new AlertDetailsRespose();
          response.applyData(responseData);
          setAlerts(response);
        },
        token
      );
    }
  });
  //---------------------------------------------------------------------------------API Calls

  //Functions---------------------------------------------------------------------------------
  const onEditAlertHandler = (alertId) => {
    setSelectedAlertId(alertId);
    setShowEditAlert(true);
  };
  const onCloseEditAlertHandler = () => {
    setShowEditAlert(false);
  };

  const onSortChangeHandler = (sortType) => {
    if (sortType == 1) {
      serSortOrder("asc");
    }
    if (sortType == 2) {
      serSortOrder("desc");
    }
  };

  const onSearchHandler = (pageable) => {
    pageable.limit = pageLimit;
    pageable.offset = 0;
    setCurrentPage(1);
    setPaginationFilter(pageable);
  };

  const onPaginationChangeHandler = (page) => {
    setPaginationFilter((prev) => {
      let pageable = new PagableFilter();
      setCurrentPage(page);
      pageable.filters = prev.filters;
      pageable.limit = pageLimit;
      pageable.offset = page * pageLimit - pageLimit;
      return pageable;
    });
  };

  const onDataChangeHandler = () => {
    getAlertsFromAPI(paginationFilter);
  };
  //---------------------------------------------------------------------------------Functions

  return (
    <>
      <FilterComponent onSearch={onSearchHandler} notFilter={notFilter} />
      <div className="h-full flex flex-col mt-[20px]">
        <div
          className="flex flex-wrap content-start overflow-y-auto h-[65vh] overflow-x-hidden border-[2px] rounded-xl border-purple-300 shadow-inner py-[5px]"
          id="style-scroll-bar"
        >
          {alerts.alerts.map((alert) =>
            alertDataCard(alert, onEditAlertHandler)
          )}
        </div>
        <EditAlert
          showEditAlert={showEditAlert}
          onCloseEditAlertHandler={onCloseEditAlertHandler}
          alertId={selectedAlertId}
          dataChangeHandler={onDataChangeHandler}
          type={type}
        />
        <div id="pagination" className={stylePagination.container}>
          <div id="filter-prev-btn" className={stylePagination.btn}>
            <Pagination
              count={totalPage}
              shape="rounded"
              onChange={(event, page) => {
                onPaginationChangeHandler(page);
              }}
              page={currentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
