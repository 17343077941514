import "./App.css";
import Home from "./component/layout/Home.jsx";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import {
  Security,
  SecureRoute,
  LoginCallback,
  useOktaAuth,
} from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import AllAlert from "./component/pages/allAlerts/AllAlert.jsx";
import Dashboard from "./component/pages/dashboard/Dashboard.jsx";
import PageLoader from "./component/utils/PageLoader.jsx";
import { createContext, useState } from "react";
import useHttp from "./hooks/useHttp.js";
import { userLogin } from "./service/Endpoints.js";
import MyAlert from "./component/pages/myAlerts/MyAlert.jsx";
import { UserDataRespose } from "./dto/ResponeDTO.ts";
import NotFound from "./component/pages/404/404.jsx";
import Overlay from "./component/utils/OverLay.jsx";
import History from "./component/pages/history/History.jsx";
import { NotificationProvider } from "./component/utils/Notification/NotificationContext.jsx";
import Notification from "./component/utils/Notification/Notification.jsx";
import ReactDOM from "react-dom";

const CLIENT_ID = window._env_.REACT_AUTH_CLIENT_ID;
const ISSUER = window._env_.REACT_AUTH_ISSUER;
const REDIRECT_URI = window._env_.REACT_AUTH_REDIRECT_URI;

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  responseType: ["id_token", "token"],
};
const oktaAuth = new OktaAuth(config);
export const AppDataContext = createContext({
  tab: 0,
  setSelectedTab: () => {},
  userData: new UserDataRespose(),
});

const App = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [userData, setUserData] = useState(new UserDataRespose());
  const history = useHistory();
  const { isLoading, error, sendRequest: callApi } = useHttp();

  oktaAuth.authStateManager.subscribe((authState) => {
    if (authState.isAuthenticated) {
      const user = new UserDataRespose();
      user.email = authState.idToken.claims.email;
      user.name = authState.idToken.claims.name;
      user.userId = authState.idToken.claims.sub;
      setUserData(user);
    }
  });
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    let token = _oktaAuth.authStateManager._authState.accessToken.accessToken;
    callApi(
      { url: userLogin() },
      (responseData) => {
        let response = new UserDataRespose();
        response.applyData(responseData);
        setUserData(response);
      },
      token
    );
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <>
      <AppDataContext.Provider
        value={{
          setSelectedTab: setSelectedTab,
          tab: selectedTab,
          userData: userData,
        }}
      >
        <Overlay>
          <PageLoader>
              <NotificationProvider>
                {ReactDOM.createPortal(<Notification />, document.body)}

                <Router>
                  <Security
                    restoreOriginalUri={restoreOriginalUri}
                    oktaAuth={oktaAuth}
                  >
                    <Route path="/login/callback" component={LoginCallback} />
                    <Home>
                      <SecureRoute path="/login" component={Dashboard} />
                      <SecureRoute
                        path="/"
                        exact={true}
                        component={Dashboard}
                      />
                      <SecureRoute
                        path="/dashboard"
                        exact={true}
                        component={Dashboard}
                      />
                      <SecureRoute
                        path="/all-alerts"
                        exact={true}
                        component={AllAlert}
                      />
                      <SecureRoute
                        path="/my-alerts"
                        exact={true}
                        component={MyAlert}
                      />
                      <SecureRoute
                        path="/history"
                        exact={true}
                        component={History}
                      />
                      <Route path="/error" component={NotFound} />
                    </Home>
                  </Security>
                </Router>
              </NotificationProvider>
          </PageLoader>
        </Overlay>
      </AppDataContext.Provider>
    </>
  );
};

export default App;
