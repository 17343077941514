import useHttp from "../../../hooks/useHttp";
import { getMonthStats } from "../../../service/Endpoints";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { MonthlyStatsResponse } from "../../../dto/ResponeDTO.ts";

import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend
} from "recharts";
const stylePagination = {
  container: "flex flex-row h-4 m-auto justify-center mb-5",
  btn: "w-10  mt-2 shadow-xl shadow-fuchsia-200 rounded-lg text-fuchsia-700 text-xl transition ease-in-out delay-20  hover:scale-110 hover:duration-300 active:shadow-inner active:shadow-fuchsia-300",
};

let typeStatArchive = {};
let verdictStatArchive = {};

const TYPE_STAT_COLORS = [
  "#E58A9D",  // Muted version of #FF77A9
  "#7E6AA6",  // Muted version of #9370DB
  "#BEBEBE",  // Muted version of #D3D3D3
  "#D48FD4",  // Muted version of #EE82EE
  "#C0A8C0"   // Muted version of #D8BFD8
];

const PieChartWith3D = ({ data, colors }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={80}
          innerRadius={30}
          fill="#8884d8"
          paddingAngle={5}
          isAnimationActive={true}
          animationDuration={1500}
          animationEasing="ease-out"
          style={{ filter: "drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.4))" }} // 3D-like shadow
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.fill ? entry.fill : colors[index % colors.length]}
              stroke="#000"
              strokeWidth={0.2}
            />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          align="center"
          verticalAlign="bottom"
          wrapperStyle={{
            fontSize: "10px",
            paddingLeft: "10px",
            height: "200px",
          }}
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function PieStat() {
  //hooks
  const { isLoading, error, sendRequest: callApi } = useHttp();
  const { authState } = useOktaAuth();
  const [activeTypeStat, setActiveTypeStat] = useState([]);
  const [activeVerdictStat, setActiveVerdictStat] = useState();
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  const getStats = useCallback((monthId) => {
    if (typeStatArchive[monthId] && verdictStatArchive[monthId]) {
      setActiveTypeStat(typeStatArchive[monthId]);
      setActiveVerdictStat(verdictStatArchive[monthId]);
    } else {
      let token = authState.accessToken.accessToken;
      callApi(
        { url: getMonthStats(monthId) },
        (responseData) => {
          const response = new MonthlyStatsResponse();
          response.applyData(responseData);
          let typeArray = [];
          let verdictArray = [];
          let count = 1;
          for (const key in response.typeStat) {
            let data = {};
            data["name"] = key;
            data["value"] = Number(response.typeStat[key]);
            typeArray.push(data);
            count++;
          }
          count = 1;
          for (const key in response.verdictStat) {
            let data = {};
            data["name"] = key;
            data["value"] = Number(response.verdictStat[key]);
            data["fill"] =
              key == "FRAUD"
                ? "#F8BFB0"
                : key == "NOT FRAUD"
                ? "#A8D5BA"
                : "#FCE8B2";
            verdictArray.push(data);
            count++;
          }
          typeStatArchive[monthId] = typeArray;
          verdictStatArchive[monthId] = verdictArray;
          setActiveTypeStat(typeStatArchive[monthId]);
          setActiveVerdictStat(verdictStatArchive[monthId]);
        },
        token
      );
    }
  });
  useEffect(() => {
    getStats(currentMonth + 1);
  }, []);

  const onMonthChange = (action) => {
    if (action === "PREV") {
      let newMonth = currentMonth - 1;
      if (currentMonth > -1) {
        setCurrentMonth(newMonth);
        getStats(newMonth + 1);
      }
    }
    if (action === "NEXT") {
      let newMonth = currentMonth + 1;
      if (currentMonth < 11) {
        setCurrentMonth(newMonth);
        getStats(newMonth + 1);
      }
    }
  };

  return (
    <>
      {activeTypeStat && activeVerdictStat && (
        <div className="rounded-xl bg-white w-[90%] h-[37vh] mx-auto shadow-xl">
          <div id="pagination" className={stylePagination.container}>
            <div id="filter-prev-btn">
              <button
                className={stylePagination.btn}
                onClick={() => onMonthChange("PREV")}
              >
                <vwc-icon type="double-chevron-left-solid" size="medium" />
              </button>
            </div>
            <div className="mx-10 text-base font-mono">
              {month[currentMonth]}
            </div>
            <div id="filter-next-btn">
              <button
                className={stylePagination.btn}
                onClick={() => onMonthChange("NEXT")}
              >
                <vwc-icon type="double-chevron-right-solid" size="medium" />
              </button>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div className="w-[400px]">
              <PieChartWith3D data={activeTypeStat} colors={TYPE_STAT_COLORS} />
            </div>
            <div className="w-[400px]">
              <PieChartWith3D data={activeVerdictStat} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
