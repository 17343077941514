import { NavItem } from './../../node_modules/@vonage/vivid/lib/nav-item/nav-item.d';
import { UseNumberInputDecrementButtonSlotOwnProps } from './../../node_modules/@mui/base/unstable_useNumberInput/useNumberInput.types.d';

export class AlertDetailsRespose {
  alerts:Array<AlertDetail>=[];
  totalRecords:Number=0;
  offset:Number;
  limit:Number;
  constructor() {
  }
  applyData(json:Object){
    Object.assign(this, json)
  }
}

export class AlertDetail {
  alertId:string;
  alertType:string;
  accountId:string;
  status:string;
  assignedTo:string;
  assignedToId:string
  createdOn:string;
  assignedOn:string;
  verdict:string;
  verdictOn:string;
  constructor() {
  }
  applyData(json:Object){
    Object.assign(this, json)
  }
}

export class AlertDataRespose {
  alertId:string;
  alertType:string;
  status:string;
  assignedTo:string;
  assignedToId:string
  createdOn:string;
  assignedOn:string;
  details:String;
  verdict:String;
  verdictOn:String;
  assignmentHistory:Array<AssignmentDetail>=[];
  constructor() {
  }
  applyData(json:Object){
    Object.assign(this, json)
  }
}
export class AssignmentDetail {
  assignee:string;
  from:Date;
  to:Date;
  constructor() {
  }
  applyData(json:Object){
    Object.assign(this, json)
  }
}

export class UserDataRespose {
  userId:string;
  name:string;
  email:string;
  constructor() {
  }
  applyData(json:Object){
    Object.assign(this, json)
  }
}

export class MonthlyStatsResponse {
  typeStat:{};
  verdictStat:{};
  totalStats:{};
  constructor() {
  }
  applyData(json:Object){
    Object.assign(this, json)
  }
}

export class UserDetailResponse {
  users:Array<UserDataRespose>;
  constructor() {
  }
  applyData(json:Object){
    Object.assign(this, json)
  }
}



