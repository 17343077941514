import vonageLogo from "../../assets/vonage_logo.png";
import "@vonage/vwc-icon";
import { useOktaAuth } from "@okta/okta-react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppDataContext } from "../../App";
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import Tooltip from '@mui/material/Tooltip';
export default function Header() {
  const selectTabContext = useContext(AppDataContext);
  const tabs = [
    {
      name: "DASHBOARD",
      path: "/dashboard",
      logo: <DashboardIcon  />,
    },
    {
      name: "ALERTS",
      path: "/all-alerts",
      logo: <NotificationsActiveIcon />,
    },
    {
      name: "MY TASKS",
      path: "/my-alerts",
      logo: <AssignmentTurnedInIcon />,
    },
    {
      name: "HISTORY",
      path: "/history",
      logo: <WorkHistoryIcon />,
    },
  ];

  const { authState, oktaAuth } = useOktaAuth();

  const logoutHandler = async () => await oktaAuth.signOut("/login");

  return (
    <div className="flex flex-row bg-black mx-auto rounded-b-3xl shadow-2xl font-semibold">
      <div className="basis-1/8">
        <img
          src={vonageLogo}
          style={{
            width: 60,
            height: 60,
            borderEndStartRadius: 20,
          }}
        />
      </div>
      <div
        className="flex 
                   flex-row 
                   space-x-20  
                   m-auto"
      >
        {tabs.map((tab, index) => (
          <Link to={tab.path} key={index}>
            <button
              className={`relative
                          text-gray-300
                          tracking-wider 
                          text-base 
                          tracking-wider
                          ${
                            selectTabContext.tab === index &&
                            "header-box-selected"
                          } group`}
              onClick={() => selectTabContext.setSelectedTab(index)}
            >
              <div className="flex flex-row items-center w-fit">
                <div className={`text-neutral-300 ${selectTabContext.tab===index?"text-purple-500":"text-white"}`}>{tab.logo}</div>
                <div className="ml-2 text-[12px] text-white">{tab.name}</div>
              </div>
              <span
                className={`absolute bottom-[-1] left-0 h-[2px] bg-violet-600 transition-all duration-300 group-hover:w-[110%] ${selectTabContext.tab===index?"w-6":"w-6"}`}
              ></span>
            </button>
          </Link>
        ))}

        <div className="text-white text-xl hover:text-red-500 absolute top-4 right-7">
        <Tooltip title="Logout">
          <button
            id="log-out"
            onClick={logoutHandler}
            className=" bg-neutral-900 h-10 w-10 rounded-lg shadow-inner shadow-neutral-800 active:shadow-inner active:shadow-neutral-400"
          >
            <vwc-icon type="enter-line" size="large"></vwc-icon>
          </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
